import React from "react"
import LayoutWeek from "../../../components/Chronology/LayoutWeek"
import ModAlertBanner from "../../../components/Chronology/Modules/ModAlertBanner"
import Day110 from "./dia-01-jul"
import Day111 from "./dia-02-jul"
import Day112 from "./dia-03-jul"
import Day106 from "./dia-27-jun"
import Day107 from "./dia-28-jun"
import Day108 from "./dia-29-jun"
import Day109 from "./dia-30-jun"

const Week = props => {
  return (
    <LayoutWeek {...props}>
      <ModAlertBanner color="brand02">
        Estados unidos declara 55.000 casos nuevos, el mayor aumento diario que cualquier país haya
        registrado
      </ModAlertBanner>
      <Day112 {...props} />
      <Day111 {...props} />
      <Day110 {...props} />
      <Day109 {...props} />
      <Day108 {...props} />
      <Day107 {...props} />
      <Day106 {...props} />
    </LayoutWeek>
  )
}

export default Week
