import React from "react"
import LayoutDay from "../../../components/Chronology/LayoutDay"
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft"
import ContentRight from "../../../components/Chronology/Modules/ContentRight"
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable"
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight"
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu"
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu"
import ModText from "../../../components/Chronology/Modules/ModText"

export const frontmatter = {
  title: "Día 107",
  week: "Semana 16",
  day: "28",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-28",
  path: "/cronologia/semana-16#dia-28-jun/",
}

const Day107 = props => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en <strong>España</strong> 1.915 casos
          mediante pruebas PCR. En ese mismo periodo, 137 personas han requerido hospitalización
          (7,2% de los diagnósticos), de los que 16 han sido ingresos en la UCI, y se han producido
          13 fallecimientos.
        </ModText>
        <ModCovidHighlight>Primer día sin fallecidos por Covid en Madrid</ModCovidHighlight>
        <ModCCAATable fecha={frontmatter.date} />
      </ContentRight>
    </LayoutDay>
  )
}
export default Day107
